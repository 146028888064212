import { render, staticRenderFns } from "./ImageToTextInput.vue?vue&type=template&id=36700099&scoped=true&"
import script from "./ImageToTextInput.vue?vue&type=script&lang=js&"
export * from "./ImageToTextInput.vue?vue&type=script&lang=js&"
import style0 from "./ImageToTextInput.vue?vue&type=style&index=0&id=36700099&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36700099",
  null
  
)

export default component.exports