<template>
  <div class="family-tree-library-page">
    <modal :name="tagsSelectModalName" classes="clear_modal" v-if="isEditAllowed">
      <bulk-upload-modal-content
        :files-len="selectedFiles.length"
        :family-tree-id="libraryFamilyTreeIdState"
        @confirm="bulkUploadConfirm"
      ></bulk-upload-modal-content>
    </modal>
    <library-no-access v-if="noAccess"></library-no-access>
    <div class="limited_content" v-else>
      <div class="library-header">
        <div class="bold">Photos & Files</div>
        <div class="upload-options" v-if="isEditAllowed">
          <a v-if="isDesktop" class="library-upload-button" @click="bulkUploadButtonClick" v-tooltip="uploadTooltip"
            >+ Bulk Upload</a
          >
          <a class="library-upload-button" @click="uploadButtonClick" v-tooltip="uploadTooltip">{{
            quickUploadLabel
          }}</a>
          <router-link v-if="isDesktop" :to="toLibraryUpload" class="library-upload-button" target="_blank"
            ><upload-icon :size="16"></upload-icon>Upload</router-link
          >
        </div>
        <input type="file" multiple class="files-input" :ref="filesInputRef" @change="fileInputChange" />
      </div>
      <div class="tree-selector" v-if="showSelector">
        <family-tree-selector
          :list="familyTreeListState"
          :tree-id="libraryFamilyTreeIdState"
          :tree-name="libraryFamilyTreeName"
          :display-inline="true"
          @select="onTreeSelect"
        ></family-tree-selector>
      </div>
      <search-bar-container @select="filterSelected"></search-bar-container>
      <active-filters-container @remove="activeFilterRemoved"></active-filters-container>

      <div class="results-meta" v-if="!assetsLoading && familyTreeLibraryAssetsDisplayMetaState.total_count">
        <base-pagination-meta :meta="familyTreeLibraryAssetsDisplayMetaState"></base-pagination-meta>
        <library-assets-ordering-container @change="orderingChanged"></library-assets-ordering-container>
      </div>
      <div class="empty-results-meta" v-else></div>

      <mcr-loading-indicator v-if="assetsLoading" :loading="true"></mcr-loading-indicator>
      <library-assets-container
        v-else
        @add-files="uploadButtonClick"
        @drop-files="dropFiles"
        @clear-filters="clearFilters"
      ></library-assets-container>

      <div
        v-if="!assetsLoading && (familyTreeLibraryAssetsMetaState.next || familyTreeLibraryAssetsMetaState.previous)"
        class="pagination-container"
      >
        <base-pagination :meta="familyTreeLibraryAssetsMetaState" @onSwitchPage="onSwitchPage"></base-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  FT_LIBRARY_ASSET_DETAILS_ROUTE_NAME,
  FT_LIBRARY_ROUTE_NAME,
  FT_LIBRARY_UPLOAD_ROUTE_NAME,
} from '@/router/consts';
import BasePagination from '@common/elements/BasePagination.vue';
import BasePaginationMeta from '@common/elements/BasePaginationMeta.vue';
import consts from '@common/utils/consts';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import UploadIcon from 'vue-material-design-icons/Upload.vue';
import {mapGetters} from 'vuex';

import FamilyTreeSelector from '@/components/common/inputs/familyTreeSelector';
import ExtractTextInterstitial from '@/components/common/tree/ExtractTextInterstitial.vue';

import LibraryNoAccess from '@/components/modules/familyTreeLibrary/libraryNoAccess';

import {FILTER_LOCATIONS, FILTER_PERSONS, FILTER_SEARCH} from './consts';
import ActiveFiltersContainer from './list/ActiveFiltersContainer';
import LibraryAssetsContainer from './list/LibraryAssetsContainer';
import LibraryAssetsOrderingContainer from './list/LibraryAssetsOrderingContainer';
import SearchBarContainer from './list/SearchBarContainer';
import BulkUploadModalContent from './list/modals/BulkUploadModalContent';

export default {
  metaInfo: {
    title: 'Photos & Files',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Preserve and organize your family memories, photos and files, and connect them with people in your family tree.',
      },
      {
        vmid: 'og:image:secure_url',
        property: 'og:image:secure_url',
        content: 'https://cdn.mychinaroots.com/src/assets/services/library_splash2.png',
      },
    ],
  },
  async created() {
    window.onbeforeunload = () => {
      if (this.fileIsUploading) {
        return 'Are you sure you want to leave?';
      }
    };

    if (
      this.familyTreeLibraryAssetsState.length &&
      this.previousRouteState &&
      this.previousRouteState.name === FT_LIBRARY_ASSET_DETAILS_ROUTE_NAME
    ) {
      this.$router
        .replace({
          query: {ordering: this.familyTreeAssetsOrderingState, ...this.familyTreeLibraryFiltersAsStringsState},
        })
        .catch(() => {});
      if (this.assetWasDeletedState) {
        this.fetchLibraryAssets(1);
        this.$store.commit('setAssetWasDeletedState', false);
      }
      return;
    }
    this.$store.commit('setFamilyTreeLibraryAssetsLoadingState', true);
    this.$store.commit('clearFamilyTreeLibraryState');
    this.$store.commit('setLibraryFamilyTreeIdState', parseInt(this.$route.params.id));
    this.$store.dispatch('fetchFamilyTreeListAction');
    this.$store.commit('setFamilyTreeAssetsOrderingState', this.$route.query.ordering);
    const personsIds = this.$route.query.persons;
    const persons = personsIds
      ? await this.$store.dispatch('fetchFamilyTreePersonsFullNamesByIdsAction', personsIds)
      : [];
    this.setActiveFiltersFromQuery(persons);
    this.fetchLibraryAssets(1);
  },
  destroyed() {
    window.onbeforeunload = () => {};
  },
  watch: {
    $route: {
      handler: function (toRoute, fromRoute) {
        const sameRoute = toRoute.name === fromRoute.name;
        const changedFtId = parseInt(toRoute.params.id) !== parseInt(fromRoute.params.id);
        if (sameRoute && changedFtId) {
          this.$store.commit('setFamilyTreeLibraryAssetsLoadingState', true);
          this.$store.commit('setLibraryFamilyTreeIdState', parseInt(toRoute.params.id));
          this.$store.commit('clearFamilyTreeLibraryAssetsFiltersState');
          this.fetchLibraryAssets(1);
        }
      },
    },
  },
  data() {
    return {
      filtersQueryParams: [FILTER_PERSONS, FILTER_LOCATIONS, FILTER_SEARCH],
      filesInputRef: 'files-input',
      maxFileSizeMB: consts.maxFileSizeMB,
      maxFileSize: consts.maxFileSizeMB * 1024 * 1024,
      isBulkUpload: false,
      selectedFiles: [],
      tagsSelectModalName: 'tags-select',
      noAccess: false,
    };
  },
  computed: {
    ...mapGetters([
      'familyTreeLibraryAssetsLoadingState',
      'familyTreeLibraryAssetsMetaState',
      'familyTreeLibraryAssetsDisplayMetaState',
      'libraryFamilyTreeIdState',
      'familyTreeAssetsOrderingState',
      'familyTreeLibraryFiltersAsStringsState',
      'familyTreeLibraryAssetsState',
      'familyTreeLibraryFiltersGroupedState',
      'assetWasDeletedState',
      'previousRouteState',
      'familyTreeListState',
    ]),
    uploadTooltip() {
      return {content: `Up to ${this.maxFileSizeMB}MB per file`};
    },
    quickUploadLabel() {
      return this.isDesktop ? '+ Quick Upload' : '+ Upload';
    },
    assetsLoading() {
      return this.familyTreeLibraryAssetsLoadingState;
    },
    fileIsUploading() {
      for (let file of this.familyTreeLibraryAssetsState) {
        if (file.uploading && !file.error) {
          return true;
        }
      }
      return false;
    },
    showSelector() {
      const inList = this.familyTreeListState.find(item => item.object_id === this.libraryFamilyTreeIdState);
      if (inList) {
        return this.familyTreeListState.length > 1;
      }
      return this.libraryFamilyTreeIdState && this.libraryFamilyTreeName;
    },
    libraryFamilyTreeName() {
      return (
        this.familyTreeLibraryAssetsMetaState.family_tree && this.familyTreeLibraryAssetsMetaState.family_tree.name
      );
    },
    isEditAllowed() {
      const tree = this.familyTreeLibraryAssetsMetaState.family_tree;
      return tree && (tree.is_owner || tree.is_editor);
    },
    toLibraryUpload() {
      return {
        name: FT_LIBRARY_UPLOAD_ROUTE_NAME,
        params: {id: this.libraryFamilyTreeIdState},
      };
    },
    isDesktop() {
      const width = this.$store.getters.windowWidthState;
      return width > this.$breakpoints.mobile;
    },
  },
  methods: {
    beforeRouteLeave(to, from, next) {
      /* used in parent component */
      if (to.name !== FT_LIBRARY_ASSET_DETAILS_ROUTE_NAME && this.fileIsUploading) {
        return confirm(' Are you sure you want to leave? Changes you made may not be saved.') ? next() : null;
      }
      return next();
    },
    setIsBulkUpload(bool) {
      this.isBulkUpload = bool;
    },
    saveFiltersToQuery() {
      const newValue = this.familyTreeLibraryFiltersGroupedState;
      const otherQuery = pickBy(this.$route.query, (value, key) => !this.filtersQueryParams.includes(key));
      this.$router.push({query: {...otherQuery, ...newValue}}).catch(() => {});
    },
    activeFilterRemoved() {
      this.saveFiltersToQuery();
      this.fetchLibraryAssets();
    },
    filterSelected() {
      this.saveFiltersToQuery();
      this.fetchLibraryAssets();
    },
    fetchLibraryAssets: debounce(function (page) {
      this.fetchLibraryAssetsForce(page);
    }, 500),
    fetchLibraryAssetsForce(page) {
      this.noAccess = false;
      const params = {
        familyTreeId: this.libraryFamilyTreeIdState,
        page: page || 1,
        orderBy: this.familyTreeAssetsOrderingState,
        ...this.familyTreeLibraryFiltersAsStringsState,
      };
      return this.$store.dispatch('fetchFamilyTreeLibraryAssetsAction', params).catch(() => {
        this.noAccess = true;
      });
    },

    onSwitchPage(page) {
      this.fetchLibraryAssets(page);
    },
    orderingChanged() {
      this.fetchLibraryAssets(1);
    },
    runFilesValidation(files) {
      for (let file of files) {
        if (file.size > this.maxFileSize) {
          const action = [
            {
              text: 'Use Uploader',
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
                this.$router.push({name: FT_LIBRARY_UPLOAD_ROUTE_NAME, params: {id: this.libraryFamilyTreeIdState}});
              },
            },
          ];

          this.handleFilesSelectError(`File max size: ${this.maxFileSizeMB}MB.`, action);
          return true;
        }
      }
    },
    dropFiles(files) {
      if (this.runFilesValidation(files)) {
        return;
      }
      this.filesSelected(files);
    },
    fileInputChange(event) {
      this.selectedFiles = event.target.files;
      if (this.runFilesValidation(this.selectedFiles)) {
        return;
      }
      if (this.isBulkUpload && this.selectedFiles && this.selectedFiles.length > 1) {
        this.$modal.show(this.tagsSelectModalName);
      } else {
        this.filesSelected(this.selectedFiles);
      }
    },
    bulkUploadConfirm(tagsData) {
      let newTagsData = {
        persons: tagsData.persons.map(person => ({pk: person.object_id})),
        location_tag: tagsData.location,
        event_date: tagsData.event_date,
        description: tagsData.description,
      };
      this.filesSelected([...this.selectedFiles], newTagsData);
      this.$modal.hide(this.tagsSelectModalName);
    },
    async filesSelected(files, tagsData = {}) {
      if (!this.isEditAllowed) {
        return;
      }
      if (!isEmpty(this.familyTreeLibraryFiltersAsStringsState)) {
        await this.clearFilters();
      }
      for (let file of files) {
        this.$store
          .dispatch('uploadFamilyTreeLibraryAssetAction', {
            familyTreeId: this.libraryFamilyTreeIdState,
            file,
            tagsData,
          })
          .then(response => {
            if (response.show_text_extract_tutorial) {
              this.$modal.show(
                ExtractTextInterstitial,
                {},
                {
                  clickToClose: true,
                  scrollable: true,
                  height: 'auto',
                  classes: 'clear_modal white_modal',
                  class: 'mobile_bottom',
                }
              );
            }
          });
      }
      this.clearInputFiles();
    },
    handleFilesSelectError(errorText, action) {
      this.clearInputFiles();
      this.$toasted.error(errorText, {action});
    },
    clearInputFiles() {
      if (this.$refs && this.$refs[this.filesInputRef]) {
        this.$refs[this.filesInputRef].value = '';
      }
    },
    uploadButtonClick() {
      this.setIsBulkUpload(false);
      this.$refs[this.filesInputRef].click();
    },
    bulkUploadButtonClick() {
      this.setIsBulkUpload(true);
      this.$refs[this.filesInputRef].click();
    },
    setActiveFiltersFromQuery(persons) {
      this.$store.commit('setFamilyTreeLibraryAssetsFiltersState', []);
      const displayNameByFilterType = {
        [FILTER_SEARCH]: filterValue => `"${filterValue}"`,
        [FILTER_PERSONS]: filterValue => {
          const person = persons.find(p => p.object_id.toString() === filterValue.toString());
          return person ? person.full_name || 'Unknown' : '';
        },
        [FILTER_LOCATIONS]: filterValue => filterValue,
      };
      for (let filterType of Object.keys(this.$route.query)) {
        if (!this.filtersQueryParams.includes(filterType)) {
          continue;
        }
        let values =
          filterType === FILTER_PERSONS
            ? this.$route.query[filterType].toString().split(',')
            : this.$route.query[filterType];
        values = typeof values === 'string' ? [values] : values;
        for (let filterValue of values) {
          filterValue = decodeURIComponent(filterValue);
          let displayName = displayNameByFilterType[filterType](filterValue);
          if (displayName) {
            this.$store.commit('addToFamilyTreeLibraryAssetsFilterState', {filterType, filterValue, displayName});
          }
        }
      }
    },
    clearFilters() {
      this.$store.commit('clearFamilyTreeLibraryAssetsFiltersState');
      this.saveFiltersToQuery();
      return this.fetchLibraryAssetsForce();
    },
    onTreeSelect(value) {
      this.$router.push({name: FT_LIBRARY_ROUTE_NAME, params: {id: value.object_id}});
    },
  },
  components: {
    BasePaginationMeta,
    LibraryNoAccess,
    BulkUploadModalContent,
    FamilyTreeSelector,
    ActiveFiltersContainer,
    SearchBarContainer,
    LibraryAssetsOrderingContainer,
    BasePagination,
    LibraryAssetsContainer,
    UploadIcon,
  },
};
</script>

<style lang="scss" scoped>
.family-tree-library-page {
  .library-header {
    display: flex;
    justify-content: space-between;
    .upload-options {
      display: flex;
      align-items: center;

      .library-upload-button {
        vertical-align: center;
        display: flex;
        align-items: center;
      }

      .library-upload-button:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .tree-selector::v-deep {
    margin-top: 10px;
    .family-tree-multiselect {
      max-width: fit-content;
      width: fit-content;

      &.multiselect--active {
        max-width: 380px;
        width: 380px;
      }

      @media only screen and (max-width: $breakpoint-mobile) {
        max-width: 100%;
        &.multiselect--active {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  .search-bar-multiselect::v-deep {
    margin-top: $paragraph-margin-size;
    input::placeholder {
      color: $supplemental-text-color;
    }
  }

  .results-meta {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-top: 24px;

    @media only screen and (max-width: $breakpoint-mobile-se) {
      font-size: 0.8em;
      margin-top: 18px;
      flex-direction: column;

      .mcr-select {
        margin: 10px 0;
        min-width: 0;
      }
    }
  }
  .empty-results-meta {
    margin-top: 45px;
    @media only screen and (max-width: $breakpoint-mobile-se) {
      margin-top: 39px;
    }
  }

  .pagination-container {
    display: flex;
    align-items: center;
    padding-bottom: 50px;
    .pagination {
      justify-content: center;
      &:not(.is-mobile)::v-deep .jump-to-page {
        margin-left: 20px;
      }
    }
  }
  .files-input {
    display: none;
  }
}
</style>
